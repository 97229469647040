import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { config } from '@config';
import language_config from '@config/language-config';
import { _resolveUriToRoute } from '@unicat/i18n/LanguageContext';

const query = graphql`
  query {
    site {
      meta: siteMetadata {
        siteUrl
      }
    }
  }
`;

/** Resolve current page URI to it localized version. */
const resolvePageUri = (metaSiteUrl, lg, location) => {
  let currentPage = '';

  if (typeof location !== 'undefined') {
    currentPage = location.pathname;
  } else {
    if (typeof window === 'undefined') {
      return;
    }

    currentPage = window.location.pathname;
  }

  const route = _resolveUriToRoute(currentPage);
  const path = typeof route === 'undefined' ? currentPage : route.pathList[lg.path].path;
  return `${metaSiteUrl}${path}`;
};

/** Configure Multi language support in page header. */
function MultiLanguage({ lang, location }) {
  const { site } = useStaticQuery(query);

  const metaSiteUrl = site.meta.siteUrl;

  return (
    <>
      {/* alternative languages, https://web.dev/hreflang/ */}
      {/* Test https://technicalseo.com/tools/hreflang/ */}
      {/* Generator: https://www.aleydasolis.com/english/international-seo-tools/hreflang-tags-generator/ */}
      <Helmet>
        <meta charSet={'utf-8'} />
        {/* Locales */}
        <html lang={language_config[lang].iso639} />
        {/* language selector available on HOME page */}
        <link rel="alternate" href={metaSiteUrl} hrefLang="x-default" />
        {/* specify language for HOME page */}
        <link rel="alternate" href={metaSiteUrl} hrefLang={config.DefaultLanguage} />
        {/* link all i18n versions of the current page including the current page lang */}
        {map(language_config, (lg) => (
          <link rel="alternate" hrefLang={lg.iso639} href={resolvePageUri(metaSiteUrl, lg, location)} key={lg.path} />
        ))}
        {/* multiple link elements, https://web.dev/canonical/ */}
        {/*<link rel="canonical" href={resolvePageUri(metaSiteUrl, { path: lang }, location)} />*/}
      </Helmet>
    </>
  );
}

MultiLanguage.defaultProps = {};
MultiLanguage.propTypes = {
  lang: PropTypes.string.isRequired,
  location: PropTypes.any,
};

export default MultiLanguage;
