import React from 'react';
import { Helmet } from 'react-helmet';

function Experimental() {
  return (
    <Helmet>
      {/* webp support enabled, https://bit.ly/2RcUuyp */}
      <script key={'WebPSupport'}>
        {/* eslint-disable-next-line quotes */}
        {`!function(e){"use strict";function s(s){if(s){var t=e.documentElement;t.classList?t.classList.add("webp"):t.className+=" webp",window.sessionStorage.setItem("webpSupport",!0)}}!function(e){if(window.sessionStorage&&window.sessionStorage.getItem("webpSupport"))s(!0);else{var t=new Image;t.onload=t.onerror=function(){e(2===t.height)},t.src="data:image/webp;base64,UklGRi4AAABXRUJQVlA4TCEAAAAvAUAAEB8wAiMwAgSSNtse/cXjxyCCmrYNWPwmHRH9jwMA"}}(s)}(document);`}
      </script>
    </Helmet>
  );
}

Experimental.defaultProps = {};
Experimental.propTypes = {};

export default Experimental;
