import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        author
        siteUrl
        siteImage
        twitter {
          image
          author
        }
      }
    }
  }
`;

/** Setup ViewPort, robots access, basic SEO: title, description, keywords, language. */
function SEO({ title, description, lang, keywords }) {
  const { site } = useStaticQuery(query);

  const metaDescription = description || site.meta.description;
  const metaKeywords = keywords || site.meta.keywords;
  const metaSiteUrl = site.meta.siteUrl;

  console.log('Title: ', title);

  /* https://github.com/nfl/react-helmet#reference-guide */
  return (
    <>
      {/*  defaultTitle={title} titleTemplate={`${site.meta.title} | %s`} */}
      <Helmet titleTemplate={`%s @ ${site.meta.title}`}>
        {/* View Port, responsive design */}
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        {/* Disable indexing temporary during the development time */}
        <meta name="robots" content="noimageindex, nofollow, nosnippet" />
        {/*<meta name="robots" content="index, follow">*/}

        {/* title attributes and value */}
        <title itemProp="name" lang={lang}>
          {title}
        </title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
        {/* HOME site */}
        <link rel="home" href={metaSiteUrl} />
      </Helmet>
    </>
  );
}

SEO.defaultProps = {
  description: '',
  lang: 'en',
  keywords: '',
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  lang: PropTypes.string,
  keywords: PropTypes.string,
};

export default SEO;
