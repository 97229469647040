import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        author
        siteUrl
        siteImage
        twitter {
          image
          author
        }
      }
    }
  }
`;

function TwitterCard({ title, description, image }) {
  const { site } = useStaticQuery(query);

  const metaTitle = title || site.meta.title;
  const metaDescription = description || site.meta.description;
  const metaSiteUrl = site.meta.siteUrl;
  const metaTwitterImage = `${metaSiteUrl}${image || site.meta.twitter.image}`;
  const metaAuthor = site.meta.twitter.author;

  return (
    <>
      <Helmet>
        {/* Twitter integration, https://bit.ly/3cUhiLG */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={metaAuthor} />
        <meta name="twitter:site" content={metaAuthor} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={metaTwitterImage} />
      </Helmet>
    </>
  );
}

TwitterCard.defaultProps = {
  description: '',
  image: undefined,
};

TwitterCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
};

export default TwitterCard;
