import React from 'react';
import { Helmet } from 'react-helmet';
import lemonBirdFont from '@assets/fonts/LemonBird/LemonBird-Regular.woff2';
import patrickHandFont from '@assets/fonts/PatrickHand/PatrickHand-Regular.woff2';

function PreLoad() {
  return (
    <>
      {/* look into `gatsby-ssr.js` */}
      <Helmet>
        {/* Preload Fonts */}
        <link
          rel="preload"
          href="https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN8rsOUuhpKKSTjw.woff2"
          as="font"
          type={'font/woff2'}
          crossOrigin="anonymous"
        />
        <link
          rel={'preload'}
          href={'https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2'}
          as={'font'}
          type={'font/woff2'}
          crossOrigin={'anonymous'}
        />
        <link
          rel={'preload'}
          href={'https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UNirkOUuhpKKSTjw.woff2'}
          as={'font'}
          type={'font/woff2'}
          crossOrigin={'anonymous'}
        />
        <link rel={'preload'} href={lemonBirdFont} as={'font'} type={'font/woff2'} crossOrigin={'anonymous'} />
        <link rel={'preload'} href={patrickHandFont} as={'font'} type={'font/woff2'} crossOrigin={'anonymous'} />
      </Helmet>
    </>
  );
}

PreLoad.defaultProps = {};
PreLoad.propTypes = {};

export default PreLoad;
