import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import language_config from '@config/language-config';

const query = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        author
        siteUrl
        siteImage
        twitter {
          image
          author
        }
      }
    }
  }
`;

function OpenGraph({ title, description, image, lang, pageType }) {
  const { site } = useStaticQuery(query);

  const metaTitle = title || site.meta.title;
  const metaDescription = description || site.meta.description;
  const metaSiteUrl = site.meta.siteUrl;
  const metaSiteImage = `${metaSiteUrl}${image || site.meta.siteImage}`;

  return (
    <>
      <Helmet>
        {/* Facebook Graph integration, https://ogp.me/ */}
        <meta property="og:ttl" content={604800} />
        <meta property="og:url" content={metaSiteUrl} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content={pageType} />
        <meta property="og:image" content={metaSiteImage} />
        <meta property="og:image:alt" content={metaDescription} />
        <meta property="og:locale" content={lang} />
        {map(language_config, (lg) => (
          <meta property="og:locale:alternate" content={lg.hreflang} key={lg.path} />
        ))}
      </Helmet>
    </>
  );
}

OpenGraph.defaultProps = {
  description: '',
  image: undefined,
  lang: 'en',
  pageType: 'website' /*'article'*/,
};

OpenGraph.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  pageType: PropTypes.string,
};

export default OpenGraph;
